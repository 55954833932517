window.axios = require("axios");
import Alpine from "alpinejs";
import Fuse from "fuse.js";

window.Fuse = Fuse;
window.Alpine = Alpine;

Alpine.start();

import hljs from "highlight.js/lib/core";
// import "highlight.js/styles/base16/humanoid-dark.css";
// Syntax highlighting
hljs.registerLanguage("bash", require("highlight.js/lib/languages/bash"));
hljs.registerLanguage("css", require("highlight.js/lib/languages/css"));
hljs.registerLanguage("html", require("highlight.js/lib/languages/xml"));
hljs.registerLanguage(
  "javascript",
  require("highlight.js/lib/languages/javascript")
);
hljs.registerLanguage("json", require("highlight.js/lib/languages/json"));
hljs.registerLanguage(
  "markdown",
  require("highlight.js/lib/languages/markdown")
);
hljs.registerLanguage("php", require("highlight.js/lib/languages/php"));
hljs.registerLanguage("scss", require("highlight.js/lib/languages/scss"));
hljs.registerLanguage("yaml", require("highlight.js/lib/languages/yaml"));

document.querySelectorAll("pre code").forEach((block) => {
  console.dir(block);
  hljs.highlightElement(block);
  console.dir(block);
});
